const ActionTypes = {
    ip: {
        FETCH_IP_REQUEST: 'FETCH_IP_REQUEST',
        FETCH_IP_REQUEST_FAILURE: 'FETCH_IP_REQUEST_FAILURE',
        FETCH_IP_REQUEST_SUCCESS: 'FETCH_IP_REQUEST_SUCCESS',

        SUBMI_IP_REQUEST: 'SUBMIT_IP_REQUEST',
        SUBMI_IP_REQUEST_FAILURE: 'SUBMIT_IP_REQUEST_FAILURE',
        SUBMI_IP_REQUEST_SUCCESS: 'SUBMIT_IP_REQUEST_SUCCESS',
    },
    counter: {
        FETCH_COUNTER_REQUEST: 'FETCH_COUNTER_REQUEST',
        FETCH_COUNTER_REQUEST_FAILURE: 'FETCH_COUNTER_REQUEST_FAILURE',
        FETCH_COUNTER_REQUEST_SUCCESS: 'FETCH_COUNTER_REQUEST_SUCCESS',

        RESET_COUNTER_REQUEST: 'RESET_COUNTER_REQUEST'
    },
    services: {
        FETCH_SERVICES_REQUEST: 'FETCH_SERVICES_REQUEST',
        FETCH_SERVICES_REQUEST_FAILURE: 'FETCH_SERVICES_REQUEST_FAILURE',
        FETCH_SERVICES_REQUEST_SUCCESS: 'FETCH_SERVICES_REQUEST_SUCCESS',
    },
    why_us:{
        FETCH_WHY_US_REQUEST: 'FETCH_WHY_US_REQUEST',
        FETCH_WHY_US_REQUEST_FAILURE: 'FETCH_WHY_US_REQUEST_FAILURE',
        FETCH_WHY_US_REQUEST_SUCCESS: 'FETCH_WHY_US_REQUEST_SUCCESS',
    },
    contact: {
        SUBMIT_CONTACT_REQUEST: 'SUBMIT_CONTACT_REQUEST',
        SUBMIT_CONTACT_REQUEST_FAILURE: 'SUBMIT_CONTACT_REQUEST_FAILURE',
        SUBMIT_CONTACT_REQUEST_SUCCESS: 'SUBMIT_CONTACT_REQUEST_SUCCESS',
        RESET_CONTACT_REQUEST: 'RESET_CONTACT_REQUEST',
    },
    consult:{
        SUBMIT_CONSULT_REQUEST: 'SUBMIT_CONSULT_REQUEST',
        SUBMIT_CONSULT_REQUEST_FAILURE: 'SUBMIT_CONSULT_REQUEST_FAILURE',
        SUBMIT_CONSULT_REQUEST_SUCCESS: 'SUBMIT_CONSULT_REQUEST_SUCCESS',
        RESET_CONSULT_REQUEST: 'RESET_CONSULT_REQUEST',
    },
    newsletter:{
        SUBMIT_NEWS_LETTER_REQUEST: 'SUBMIT_NEWS_LETTER_REQUEST',
        SUBMIT_NEWS_LETTER_REQUEST_FAILURE: 'SUBMIT_NEWS_LETTER_REQUEST_FAILURE',
        SUBMIT_NEWS_LETTER_REQUEST_SUCCESS: 'SUBMIT_NEWS_LETTER_REQUEST_SUCCESS',
        RESET_NEWS_LETTER_REQUEST: 'RESET_NEWS_LETTER_REQUEST',
    },
    hww:{
        FETCH_HWW_REQUEST: 'FETCH_HWW_REQUEST',
        FETCH_HWW_REQUEST_FAILURE: 'FETCH_HWW_REQUEST_FAILURE',
        FETCH_HWW_REQUEST_SUCCESS: 'FETCH_HWW_REQUEST_SUCCESS',
        RESET_HWW_REQUEST: 'RESET_HWW_REQUEST',
    },
    /**
     * For any Queries
     */
    faq:{
        FETCH_FAQ_REQUEST: 'FETCH_FAQ_REQUEST',
        FETCH_FAQ_REQUEST_FAILURE: 'FETCH_FAQ_REQUEST_FAILURE',
        FETCH_FAQ_REQUEST_SUCCESS: 'FETCH_FAQ_REQUEST_SUCCESS',
        RESET_FAQ_REQUEST: 'RESET_FAQ_REQUEST',
    },
    team:{
        FETCH_TEAM_REQUEST: 'FETCH_TEAM_REQUEST',
        FETCH_TEAM_REQUEST_FAILURE: 'FETCH_TEAM_REQUEST_FAILURE',
        FETCH_TEAM_REQUEST_SUCCESS: 'FETCH_TEAM_REQUEST_SUCCESS',
    },
    homeScreenImages:{
        FETCH_HOME_SCREEN_REQUEST: 'FETCH_HOME_SCREEN_REQUEST',
        FETCH_HOME_SCREEN_REQUEST_FAILURE: 'FETCH_HOME_SCREEN_REQUEST_FAILURE',
        FETCH_HOME_SCREEN_REQUEST_SUCCESS: 'FETCH_HOME_SCREEN_REQUEST_SUCCESS',
    },
    application:{
        FETCH_CONTENTS: 'FETCH_CONTENTS',
        FETCH_CONTENTS_SUCCESS: 'FETCH_CONTENTS_SUCCESS',
        FETCH_CONTENTS_FAILURE: 'FETCH_CONTENTS_FAILURE',

        UPDATE_LOCALE: 'UPDATE_LOCALE',
        LOAD_DEFAULTS: 'LOAD_DEFAULTS',
    }
}

export { ActionTypes }