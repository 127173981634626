import React, { useEffect } from 'react';
import Aos from 'aos'
import $ from 'jquery'
import '../assets/css/style.css'
import { CONSTANT } from '../CONSTANT';
import Loader from '../Home/Loader';
import { GUID, handleEmptyLinkClick } from '../Utilities';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '../Redux/Actions';
const SearchSection = React.lazy(() => import('./BookingForm'))
const PortfolioLink = React.lazy(() => import('../Links/PortfolioLink'))
const ContactLink = React.lazy(() => import('../Links/ContactLink'))
const HomeLink = React.lazy(() => import('../Links/HomeLink'))
const AboutLink = React.lazy(() => import('../Links/AboutLink'))
const ServiceLink = React.lazy(() => import('../Links/ServiceLink'))

function openDropdown() {
    $('#dropArea').toggleClass("activeDropArea");
}

function Header() {
    const { locale, availableLocales } = useSelector((state) => state[CONSTANT.REDUCERS.APPLICATION])
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(Actions.APPLICATION.LOCALE.load(locale))
        Aos.init();
        Aos.refresh();
    }, [locale]);
    const locationChange = useLocation()
    useEffect(() => {
        $('#menu-toggle').prop('checked', false)
    }, [locationChange])
    function handleMode(e) {
        e.preventDefault()
        $('section').toggleClass('bg-dark text-white')
        $('section').toggleClass('bg-light text-dark')
    }
    function handleLanguageChange(e) {
        dispatch(Actions.APPLICATION.LOCALE.update(e.target.value))
    }
    return (
        <React.Suspense fallback={<Loader />}>
            <header className="header">
                <nav>
                    <div className="logo">
                        <a href={CONSTANT.ROUTES.HASH} onClick={handleEmptyLinkClick}>SB<span>V<small>ishwakarma</small></span></a>
                    </div>
                    <input type="checkbox" id="menu-toggle" />
                    <label htmlFor="menu-toggle" className="menu-icon">&#9776;</label>
                    <ul className="menu">
                        <li><HomeLink /></li>
                        <li><ServiceLink /></li>
                        <li><PortfolioLink /></li>
                        <li><AboutLink /></li>
                        <li><ContactLink /></li>
                        <li>
                            <a href={CONSTANT.ROUTES.HASH} onClick={handleEmptyLinkClick}>
                                <select onChange={handleLanguageChange} className='language-drpdwn' value={locale}>
                                    {
                                        availableLocales?.map(x => <option
                                            value={x.key} key={GUID(x.key)}
                                        >{x.label}</option>)
                                    }
                                </select>
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
            <SearchSection />
        </React.Suspense>
    )
}

export default Header