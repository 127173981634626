import { ActionTypes } from "../Action Types";

const initialState = {
    locale: localStorage.getItem('lang') ?? 'EN',
    availableLocales: [
        { label: 'English', key: 'EN' },
        { label: 'हिंदी', key: 'HI' },
        { label: 'मराठीत', key: 'MR' }],
    translation: {},
    loading: false,
    loaded: false,
    error: null,
}


function applicationReducer(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.application.FETCH_CONTENTS:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.application.FETCH_CONTENTS_SUCCESS:
            return {
                ...state,
                translation: action.payload,
                loading: false
            }
        case ActionTypes.application.FETCH_CONTENTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ActionTypes.application.UPDATE_LOCALE:
            localStorage.setItem("lang", action?.payload ?? 'EN')
            return {
                ...state,
                locale: action.payload
            }
        default:
            return state;
    }
}

export default applicationReducer;