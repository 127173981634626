import { CONSTANT } from "../../CONSTANT"
import { RequestContext } from "../../Utilities/HelperClasses"
import InvokeApi from "../../Utilities/apiHelper"
import { ActionTypes } from "../Action Types"

function submitNewsLetter(email) {
    return async (dispatch) => {
        dispatch({ type: ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST })
        InvokeApi(new RequestContext("Email/subscribe?email=" + email, CONSTANT.HTTP_METHOD.GET))
            .then(res => {
                dispatch({ type: ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST_SUCCESS, payload: res.data })
            })
            .catch(err => {
                dispatch({ type: ActionTypes.newsletter.SUBMIT_NEWS_LETTER_REQUEST_FAILURE, payload: err.message })
            })
    }
}

function resetNewsLetter(){
    return (dispatch) => {
        dispatch({ type: ActionTypes.newsletter.RESET_NEWS_LETTER_REQUEST })
    }
}

export {
    submitNewsLetter,
    resetNewsLetter,
}