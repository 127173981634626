import React from 'react'
import $ from 'jquery';
import { ToggleSidebar } from '../Utilities';

function FloatingButton() {
    const handleClick = () => {
        ToggleSidebar()
        $("#container-floating").hide()
    }
    return (
        <div id="floating-button" > <button className='edit' onClick={handleClick}></button> </div>
    )
}
export default FloatingButton